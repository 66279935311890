const useLocalStorage = () => {
  const setValueToCache = (walletId, signatureKey, value) => {
    const cachedData = JSON.parse(localStorage.getItem("signatureCache")) || {};
    cachedData[walletId] = { ...cachedData[walletId], [signatureKey]: value };
    localStorage.setItem("signatureCache", JSON.stringify(cachedData));
  };

  const getValueFromCache = (walletId, signatureKey) => {
    const cachedData = JSON.parse(localStorage.getItem("signatureCache")) || {};
    return cachedData[walletId] && cachedData[walletId][signatureKey];
  };

  return {
    setValueToCache,
    getValueFromCache,
  };
};

export default useLocalStorage;
