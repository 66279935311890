import { toast } from "react-hot-toast";

const useApi = () => {
  const loginApi = async (signature) => {
    const data = await postData("https://api.turtleraces.top/login", {
      signature: signature,
    });
    return data;
  };

  const playApi = async (signature, amount, predict) => {
    const data = await postData("https://api.turtleraces.top/play", {
      signature: signature,
      amount: +amount,
      predict: predict,
    });
    return data;
  };

  const withdrawApi = async (signature, amount) => {
    const data = await postData("https://api.turtleraces.top/withdraw", {
      signature: signature,
      amount: +amount,
    });
    return data;
  };

  async function postData(url = "", data = {}) {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      return response.json(); // parses JSON response into native JavaScript objects
    } catch {
      toast.error("Server can not process the request. Try again later!!");
    }
  }
  return {
    loginApi,
    playApi,
    withdrawApi,
  };
};

export default useApi;
