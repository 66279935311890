import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useApp from "../../hooks/useApp";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../app/loadingSlice";
import { toast } from "react-hot-toast";

function Header(props) {
  const { loginApi, withdrawApi } = useApi();
  const { signMessage, deposit, withdraw } = useApp();
  const { login, logout } = useAuth();
  const { setValueToCache, getValueFromCache } = useLocalStorage();
  const dispatch = useDispatch();
  const { account, active, chainId } = useWeb3React();

  const { loading } = useSelector((state) => state.loading);

  const accountEllipsis = account
    ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}`
    : null;

  const onDepositClicked = async () => {
    if (!account || !active) {
      toast.error("Please connect your wallet!");
      return;
    }
    const depositValue = prompt("Please enter deposit value:");
    if (Number(depositValue)) {
      dispatch(setLoading(true));

      await deposit(depositValue)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const onWithdrawClicked = async () => {
    toast.success("Withdraw open at 9:00AM!");
    return;
    if (!account || !active) {
      return;
    }
    const value = prompt("Please enter withdraw value:");
    if (Number(value)) {
      let signature = await getValueFromCache(account, "withdraw");
      if (!signature) {
        const message = "withdraw";
        signature = await signMessage(message);
      }
      if (signature) {
        setValueToCache(account, "withdraw", signature);
        dispatch(setLoading(true));
        const res = await withdrawApi(signature, +value);
        if (res?.status === 1) {
          const { data } = res;
          console.log(data);
          await withdraw(
            data.transactionId,
            data.withdrawAmount,
            data.v,
            data.r,
            data.s
          );
        } else {
          dispatch(setLoading(false));
        }
      }
    }
  };
  useEffect(() => {
    const sign = async () => {
      if (!account || !active) {
        return;
      }
      let signature = await getValueFromCache(account, "login");
      if (!signature) {
        const message = "login";
        signature = await signMessage(message);
      }
      if (signature) {
        setValueToCache(account, "login", signature);
        dispatch(setLoading(true));
        const accountResponse = await loginApi(signature);
        if (accountResponse?.status === 1) {
          dispatch(setLoading(false));
          toast.success("Login successful!");
          props.sendAccountBalance(accountResponse?.data?.balance);
        } else {
          dispatch(setLoading(false));
        }
      }
    };
    sign();
  }, [account, active, chainId]);

  return (
    <header className="py-8 lg:py-5">
      <div className="container mx-auto flex flex-col gap-5 items-center justify-between lg:flex-row">
        <Link to="/" className="max-w-[200px]">
          <img src={logo} alt="" />
        </Link>
        <ul className="flex gap-10">
          <li>
            <a>About Us</a>
          </li>
          <li>
            <a>Product</a>
          </li>
          <li>
            <a>Developers</a>
          </li>
        </ul>
        <button onClick={onDepositClicked}>Deposit</button>
        <button onClick={onWithdrawClicked}>Withdraw</button>
        <button
          className="flex items-center gap-3 bg-white rounded-full text-black py-3 px-8"
          onClick={login}>
          <span className="">
            {" "}
            {(function () {
              if (active) {
                return chainId !== Number(process.env.REACT_APP_CHAIN_ID)
                  ? "Switch network"
                  : accountEllipsis;
              } else {
                return "Connect Wallet";
              }
            })()}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.8571 2.88H16.4286V2.16C16.4286 1.58713 16.2028 1.03773 15.8009 0.632649C15.3991 0.227571 14.854 0 14.2857 0H2.14286C1.57454 0 1.02949 0.227571 0.627628 0.632649C0.225765 1.03773 0 1.58713 0 2.16V15.84C0 16.4129 0.225765 16.9623 0.627628 17.3674C1.02949 17.7724 1.57454 18 2.14286 18H13.5714H17.8571C18.4255 18 18.9705 17.7724 19.3724 17.3674C19.7742 16.9623 20 16.4129 20 15.84V5.04C20 4.46713 19.7742 3.91773 19.3724 3.51265C18.9705 3.10757 18.4255 2.88 17.8571 2.88ZM1.63778 16.3491C1.77174 16.4841 1.95342 16.56 2.14286 16.56H13.5714H17.8571C18.0466 16.56 18.2283 16.4841 18.3622 16.3491C18.4962 16.2141 18.5714 16.031 18.5714 15.84V10.08V8.64V7.2V5.76V5.04C18.5714 4.84904 18.4962 4.66591 18.3622 4.53088C18.2283 4.39586 18.0466 4.32 17.8571 4.32H2.14286C1.89918 4.31805 1.65762 4.27422 1.42857 4.1904V15.84C1.42857 16.031 1.50383 16.2141 1.63778 16.3491ZM1.63778 1.65088C1.50383 1.78591 1.42857 1.96904 1.42857 2.16C1.42857 2.35096 1.50383 2.53409 1.63778 2.66912C1.77174 2.80414 1.95342 2.88 2.14286 2.88H15V2.16C15 1.96904 14.9247 1.78591 14.7908 1.65088C14.6568 1.51586 14.4752 1.44 14.2857 1.44H2.14286C1.95342 1.44 1.77174 1.51586 1.63778 1.65088Z"
              fill="#222631"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.8571 2.88H16.4286V2.16C16.4286 1.58713 16.2028 1.03773 15.8009 0.632649C15.3991 0.227571 14.854 0 14.2857 0H2.14286C1.57454 0 1.02949 0.227571 0.627628 0.632649C0.225765 1.03773 0 1.58713 0 2.16V15.84C0 16.4129 0.225765 16.9623 0.627628 17.3674C1.02949 17.7724 1.57454 18 2.14286 18H13.5714H17.8571C18.4255 18 18.9705 17.7724 19.3724 17.3674C19.7742 16.9623 20 16.4129 20 15.84V5.04C20 4.46713 19.7742 3.91773 19.3724 3.51265C18.9705 3.10757 18.4255 2.88 17.8571 2.88ZM1.63778 16.3491C1.77174 16.4841 1.95342 16.56 2.14286 16.56H13.5714H17.8571C18.0466 16.56 18.2283 16.4841 18.3622 16.3491C18.4962 16.2141 18.5714 16.031 18.5714 15.84V10.08V8.64V7.2V5.76V5.04C18.5714 4.84904 18.4962 4.66591 18.3622 4.53088C18.2283 4.39586 18.0466 4.32 17.8571 4.32H2.14286C1.89918 4.31805 1.65762 4.27422 1.42857 4.1904V15.84C1.42857 16.031 1.50383 16.2141 1.63778 16.3491ZM1.63778 1.65088C1.50383 1.78591 1.42857 1.96904 1.42857 2.16C1.42857 2.35096 1.50383 2.53409 1.63778 2.66912C1.77174 2.80414 1.95342 2.88 2.14286 2.88H15V2.16C15 1.96904 14.9247 1.78591 14.7908 1.65088C14.6568 1.51586 14.4752 1.44 14.2857 1.44H2.14286C1.95342 1.44 1.77174 1.51586 1.63778 1.65088Z"
              fill="#222631"
            />
            <path
              d="M2.14286 16.56C1.95342 16.56 1.77174 16.4841 1.63778 16.3491C1.50383 16.2141 1.42857 16.031 1.42857 15.84V4.1904C1.65762 4.27422 1.89918 4.31805 2.14286 4.32H17.8571C18.0466 4.32 18.2283 4.39586 18.3622 4.53088C18.4962 4.66591 18.5714 4.84904 18.5714 5.04V5.76V7.2V8.64V10.08V15.84C18.5714 16.031 18.4962 16.2141 18.3622 16.3491C18.2283 16.4841 18.0466 16.56 17.8571 16.56H13.5714H2.14286Z"
              fill="#222631"
            />
            <path
              d="M1.42857 2.16C1.42857 1.96904 1.50383 1.78591 1.63778 1.65088C1.77174 1.51586 1.95342 1.44 2.14286 1.44H14.2857C14.4752 1.44 14.6568 1.51586 14.7908 1.65088C14.9247 1.78591 15 1.96904 15 2.16V2.88H2.14286C1.95342 2.88 1.77174 2.80414 1.63778 2.66912C1.50383 2.53409 1.42857 2.35096 1.42857 2.16Z"
              fill="white"
            />
            <path
              d="M11.3755 11.1958C11.3755 9.9831 12.3586 9 13.5713 9H19.9998V13.3916H13.5713C12.3586 13.3916 11.3755 12.4085 11.3755 11.1958Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </header>
  );
}

export default Header;
