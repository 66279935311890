import React from "react";
import btnPlay from "../../assets/images/playgame-btn.svg";
import intro from "../../assets/images/intro.png";
import gameft from "../../assets/images/gameft.svg";
import gameft1 from "../../assets/images/gameft-1.svg";
import gameft2 from "../../assets/images/gameft-2.svg";
import p1 from "../../assets/images/mexc.svg";
import p2 from "../../assets/images/CMK.svg";
import p3 from "../../assets/images/dex.svg";
import p4 from "../../assets/images/CGC.svg";
import { Link } from "react-router-dom";

function Home(props) {
  return (
    <main>
      <section className="intro pt-16">
        <div className="container mx-auto px-5">
          <div className="grid grid-cols-1 items-center gap-16 lg:grid-cols-2">
            <div>
              <h1 className="text-5xl font-title">Turtle Races</h1>
              <p className="max-w-lg my-8">
                The core gameplay of Turtle Race revolves around exciting races
                between adorable and humorous turtles. Each turtle is
                represented by a unique ERC20 token with distinct colors and
                personalities. Players can select their favorite turtle to
                compete in the race. The outcome is determined by a mix of
                random factors and individual turtle characteristics, creating
                thrilling and dramatic races.
              </p>
              <Link to="/game">
                <img src={btnPlay} alt="" />
              </Link>
            </div>
            <div>
              <img src={intro} alt="" />
            </div>
          </div>
        </div>
        <div className="bg-black/50 py-16">
          <div className="container mx-auto px-5">
            <div className="max-w-[350px] mx-auto">
              <img src={gameft} alt="" />
            </div>
            <div className="grid grid-cols-1 gap-5 mt-16 py-16 xl:grid-cols-2">
              <div className="relative lg:translate-x-16">
                <svg
                  className="hidden lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="407"
                  viewBox="0 0 720 407"
                  fill="none">
                  <g filter="url(#filter0_di_13_3324)">
                    <path
                      d="M539.443 0.0534238C382.305 1.12199 86.9032 2.51243 16.9265 0.583193C9.87238 0.388714 4 6.08473 4 13.1415V387C4 393.627 9.37258 399 16 399H703.643C712.299 399 718.108 390.114 714.635 382.185L550.395 7.23975C548.471 2.84688 544.239 0.0208121 539.443 0.0534238Z"
                      fill="#305E13"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_di_13_3324"
                      x="0"
                      y="0.0531464"
                      width="719.656"
                      height="406.947"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_13_3324"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_13_3324"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="3.5" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow_13_3324"
                      />
                    </filter>
                  </defs>
                </svg>
                <img
                  className="relative max-w-[200px] lg:top-5 lg:right-52 lg:absolute"
                  src={gameft1}
                  alt=""
                />
                <div className="relative max-w-sm lg:absolute lg:top-40 xl:max-w-md lg:left-6">
                  <h3 className="text-xl font-title">Turtle Betting</h3>
                  <p>
                    Before the race starts, players have the option to place
                    bets on the turtle they believe will win. They can wager
                    their $TURTLE tokens to winning bets and earn rewards
                    proportional to the bet amount. The "Bet" feature adds extra
                    excitement and encourages strategic thinking among players,
                    fostering a competitive and engaging environment.
                  </p>
                </div>
              </div>
              <div className="relative lg:-translate-x-16">
                <svg
                  className="hidden lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="407"
                  viewBox="0 0 720 407"
                  fill="none">
                  <g filter="url(#filter0_di_13_3370)">
                    <path
                      d="M180.557 398.947C337.695 397.878 633.097 396.488 703.074 398.417C710.128 398.611 716 392.915 716 385.858V12C716 5.37259 710.627 0 704 0H16.3572C7.70105 0 1.89233 8.88593 5.36548 16.8148L169.605 391.76C171.529 396.153 175.761 398.979 180.557 398.947Z"
                      fill="#305E13"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_di_13_3370"
                      x="0.343872"
                      y="0"
                      width="719.656"
                      height="406.947"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_13_3370"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_13_3370"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow_13_3370"
                      />
                    </filter>
                  </defs>
                </svg>
                <img
                  className="relative max-w-[200px] lg:top-5 lg:left-40 lg:absolute"
                  src={gameft2}
                  alt=""
                />
                <div className="relative max-w-sm xl:max-w-md lg:absolute lg:top-40 lg:right-6">
                  <h3 className="text-xl font-title">Turtle Race:</h3>
                  <p>
                    Participants will be able to choose their turtle contender
                    from a variety of lovable and creatively designed turtles,
                    each with its own unique attributes and characteristics.
                    These traits may include endurance, determination, agility,
                    and more, all of which will influence the turtle's
                    performance during the race.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black/30 py-16  px-5">
          <h2 className="text-4xl font-title text-center">OUR PARTNER</h2>
          <div className="flex justify-center items-center gap-5 mt-12 lg:gap-10">
            <div className="flex justify-center items-center">
              <img src={p1} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src={p2} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src={p3} alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src={p4} alt="" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
