import { useState } from "react";
import turtle1 from "../../assets/images/t-1.svg";
import turtle2 from "../../assets/images/t-2.svg";
import turtle3 from "../../assets/images/t-3.svg";
import turtle4 from "../../assets/images/t-4.svg";
import turtle5 from "../../assets/images/t-5.svg";
import turtle6 from "../../assets/images/t-6.svg";
import bigwin from "../../assets/images/bigwin.svg";
import removed from "../../assets/images/still-removed.svg";
import winmp3 from "../../assets/mp3/bigwin.mp3";
import lostmp3 from "../../assets/mp3/lost.mp3";
import racing from "../../assets/mp3/racing-2.mp3";
import useApi from "../../hooks/useApi";
import useApp from "../../hooks/useApp";
import useLocalStorage from "../../hooks/useLocalStorage";

import { useWeb3React } from "@web3-react/core";
import styled, { keyframes } from "styled-components";
import { toast } from "react-hot-toast";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const soundWin = new Audio(winmp3);
const soundLost = new Audio(lostmp3);
const soundRacing = new Audio(racing);

const turtles = [turtle1, turtle2, turtle3, turtle4, turtle5, turtle6];

const initialResult = [null, null, null, null, null, null];

function Game({ sendAccountBalance, accountBalance }) {
  const { playApi } = useApi();
  const { signMessage } = useApp();
  const { setValueToCache, getValueFromCache } = useLocalStorage();
  const { account } = useWeb3React();
  const [result, setResult] = useState(initialResult);
  const [inputValue, setInputValue] = useState(0);
  const [myRes, setMyRes] = useState({
    pos: 1,
    turtle: 2,
  });

  const [isPlay, setIsPlay] = useState(false);

  const [selected, setSelected] = useState("");

  const [isOpenModal, setIsOpenModal] = useState(false);

  const onOpenModal = () => setIsOpenModal(true);
  const onCloseModal = () => {
    soundLost.pause();
    soundWin.pause();
    setIsOpenModal(false);
  };

  const move = keyframes`
  0% {
    transform: translateX(0);

  }
  100% {
    transform: translateX(calc(100% - 110px));
  }

    
`;

  const InfiniteMove = styled.div`
    animation: ${move} ${(props) => genDuration(props.duration)}s linear
      forwards;
  `;

  const genDuration = (pos) => {
    switch (pos) {
      case 1:
        return 5.5;
      case 2:
        return 5.9;
      case 3:
        return 6.8;
      case 4:
        return 7.5;
      case 5:
        return 8;
      case 6:
        return 8.8;
      default:
        return null;
    }
  };

  const renderResultNotice = (pos) => {
    if (pos === 1) {
      return (
        <div className="max-w-[200px] mx-auto">
          <img src={bigwin} alt="" />
        </div>
      );
    }

    if (pos === 5 || pos === 6) {
      return (
        <div className="max-w-[300px] mx-auto">
          <img src={removed} alt="" />
        </div>
      );
    }
  };

  const chooseTurtle = (e) => {
    if (!isPlay) {
      setSelected(e?.currentTarget?.id);
      console.log(e?.currentTarget?.id);
    }
  };

  const handleBetChange = (event) => {
    const newValue = event.target.value;
    setInputValue(+newValue);
  };

  const play = async () => {
    if (!selected) {
      toast.error("Please select the turtle you want to bet on");
      return;
    }
    if (isPlay) {
      toast.error("The race is not over yet");
      return;
    }
    let signature = await getValueFromCache(account, "play");
    if (!signature) {
      const message = "play";
      signature = await signMessage(message);
    }
    if (signature) {
      setValueToCache(account, "play", signature);
      const response = await playApi(signature, inputValue, selected);
      if (response?.status === 1) {
        if (selected && !isPlay) {
          soundRacing.play();
          setIsPlay(true);
          console.log(response?.data?.playResult);
          setResult(response?.data?.playResult);
          setTimeout(() => {
            sendAccountBalance(response?.data?.balance);
            soundRacing.pause();
            onOpenModal();
            setIsPlay(false);
            setResult(initialResult);
            setSelected("");
            setMyRes(response?.data?.res);
            if (myRes.pos === 1 || myRes === 2 || myRes === 3) {
              soundWin.play();
            } else {
              soundLost.play();
            }
          }, 10000);
        }
      }
    }

  };

  return (
    <main>
      <div className="intro py-16">
        <div className="container mx-auto bg-black/50 rounded-3xl border-[1px] border-white p-3">
          {result.map((item, index) => (
            <div className="turtle my-2">
              <InfiniteMove duration={item}>
                <img className="w-[100px]" src={turtles[index]} alt="" />
              </InfiniteMove>
            </div>
          ))}
        </div>

        <Modal open={isOpenModal} onClose={onCloseModal} center>
          <div className="p-5 min-w-[360px] text-center lg:min-w-[600px]">
            <h2 className="text-black font-title text-xl mt-5 text-center">
              Result
            </h2>
            <p className="text-black my-5">Position: {myRes.pos}</p>
            <div className="">
              <img
                className="mx-auto max-w-[150px] lg:max-w-[250px]"
                src={turtles[myRes.turtle]}
                alt=""
              />
            </div>

            {renderResultNotice(myRes.pos)}
          </div>
        </Modal>

        <div className="my-16 container px-5 mx-auto bg-black/50 rounded-3xl border-[1px] border-white p-3">
          <div className="flex flex-col lg:flex-row">
            {turtles.map((item, index) => (
              <div
                onClick={chooseTurtle}
                id={index + 1}
                className={`${
                  +selected === index + 1
                    ? "border-orange-400"
                    : "border-transparent"
                } cursor-pointer border-4 p-3`}
              >
                <img className="w-24 mx-auto lg:w-auto" src={item} alt="" />
              </div>
            ))}
          </div>
        </div>

        <div className="container mx-auto px-10 mt-16 flex flex-col justify-between items-center relative gap-[130px] lg:flex-row xl:px-16">
          <div className="inline-flex items-center font-title bg-[#3A8A00] border-4 border-[#00A2D4] px-5 py-3 rounded-full shadow-lg">
            <h2>Balance:</h2>
            <span>{Number(accountBalance.toFixed(1))} $TURTLE</span>
          </div>
          <button
            disabled={isPlay}
            className="cursor-pointer absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]"
            onClick={play}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="90"
              height="94"
              viewBox="0 0 90 94"
              fill="none"
            >
              <path
                d="M45.1002 93.5258C69.8977 93.5258 90 73.4235 90 48.626C90 23.8285 69.8977 3.72614 45.1002 3.72614C20.3027 3.72614 0.200317 23.8285 0.200317 48.626C0.200317 73.4235 20.3027 93.5258 45.1002 93.5258Z"
                fill="#0072AB"
              />
              <path
                d="M45.1 89.7997C69.8975 89.7997 89.9999 69.6973 89.9999 44.8998C89.9999 20.1023 69.8975 0 45.1 0C20.3025 0 0.200195 20.1023 0.200195 44.8998C0.200195 69.6973 20.3025 89.7997 45.1 89.7997Z"
                fill="url(#paint0_linear_13_3306)"
              />
              <path
                d="M45.0999 81.9748C65.5758 81.9748 82.1748 65.3758 82.1748 44.8999C82.1748 24.4239 65.5758 7.82489 45.0999 7.82489C24.6239 7.82489 8.0249 24.4239 8.0249 44.8999C8.0249 65.3758 24.6239 81.9748 45.0999 81.9748Z"
                fill="#215900"
              />
              <path
                d="M45.0999 80.4843C64.7527 80.4843 80.6844 64.5526 80.6844 44.8998C80.6844 25.247 64.7527 9.31528 45.0999 9.31528C25.4471 9.31528 9.51538 25.247 9.51538 44.8998C9.51538 64.5526 25.4471 80.4843 45.0999 80.4843Z"
                fill="url(#paint1_linear_13_3306)"
              />
              <path
                d="M45.4728 75.0815C29.6368 75.2678 16.0364 65.9525 10.6335 52.7248C14.1734 68.7471 28.519 80.6707 45.6591 80.4844C62.4267 80.2981 76.586 68.1882 79.9395 52.1658C74.7229 65.3936 61.1226 74.8952 45.4728 75.0815Z"
                fill="url(#paint2_linear_13_3306)"
              />
              <path
                d="M44.9137 14.7181C29.0777 14.9044 15.6636 24.4061 10.447 37.8201C13.6142 21.7978 27.7735 9.50154 44.9137 9.50154C61.8676 9.31524 76.2132 21.2388 79.9393 37.2612C74.3501 24.0334 60.7498 14.7181 44.9137 14.7181Z"
                fill="url(#paint3_linear_13_3306)"
              />
              <path
                opacity="0.15"
                d="M73.4187 41.9189C74.5365 35.2119 67.6432 27.5733 62.2403 24.965C56.6511 22.1704 49.9441 20.8663 43.6097 21.2389C36.7163 21.6115 30.3819 24.2198 25.1653 28.8774C18.4583 34.6529 15.85 42.2915 17.1542 47.6944C20.3214 59.8043 40.2561 57.196 51.4345 54.5877C65.9664 51.0479 72.3008 47.8807 73.4187 41.9189Z"
                fill="white"
              />
              <path
                opacity="0.53"
                d="M37.834 14.7182C37.0888 13.7867 35.412 13.4141 34.2942 13.4141C32.6174 13.4141 30.3818 14.3456 28.8913 15.2771C26.283 16.9539 19.576 21.6115 22.9295 24.2198C24.9789 25.8966 27.4009 22.3568 32.2448 20.4937C34.1079 19.7485 37.834 19.0033 38.2066 16.5813C38.3929 15.836 38.2066 15.2771 37.834 14.7182Z"
                fill="white"
              />
              <path
                opacity="0.53"
                d="M45.4725 12.2963C43.6094 11.551 39.5107 11.9236 39.8833 14.7182C40.2559 16.7676 43.982 17.1402 45.4725 16.5813C46.404 16.395 47.1492 15.4635 47.1492 14.3456C47.1492 13.4141 46.404 12.6689 45.4725 12.2963Z"
                fill="white"
              />
              <path
                opacity="0.33"
                d="M70.9966 60.5496C68.3883 59.8044 67.2705 62.599 63.1717 64.8347C60.5635 66.3251 51.9934 68.1882 53.1112 72.2869C53.4838 73.5911 54.6017 74.15 55.9058 74.3363C57.0236 74.5226 57.5826 74.7089 59.073 74.3363C60.0045 74.15 60.7498 73.7774 61.6813 73.4048C63.5444 72.6595 65.2211 71.728 66.8979 70.4239C68.5746 69.1197 70.2514 67.8156 71.3692 65.9525C72.3008 64.4621 73.6049 61.2948 70.9966 60.5496Z"
                fill="white"
              />
              <path
                d="M64.6624 39.1243L42.4919 24.2198C37.2753 20.68 30.1957 24.4061 30.1957 30.7405V64.462C30.1957 70.4238 37.4616 73.9636 42.3056 70.6101L64.6624 55.1466C70.4379 51.2342 70.4379 42.8504 64.6624 39.1243Z"
                fill="#215900"
              />
              <path
                d="M64.1032 39.4969L42.119 24.5924C37.4613 21.4252 31.3132 24.7787 31.3132 30.3679V63.5304C31.3132 69.1196 37.4613 72.4732 42.119 69.3059L64.1032 54.4014C69.3197 50.8616 69.3197 43.0367 64.1032 39.4969Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_13_3306"
                  x1="45.1474"
                  y1="89.8941"
                  x2="45.1474"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00A2D4" />
                  <stop offset="0.3156" stop-color="#32BEE1" />
                  <stop offset="1" stop-color="#AAFFFF" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_13_3306"
                  x1="45.1472"
                  y1="80.4562"
                  x2="45.1472"
                  y2="9.43787"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#3A8A00" />
                  <stop offset="0.1583" stop-color="#339900" />
                  <stop offset="0.4194" stop-color="#2AAB00" />
                  <stop offset="0.6924" stop-color="#25B600" />
                  <stop offset="0.9944" stop-color="#23BA00" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_13_3306"
                  x1="10.6209"
                  y1="66.7489"
                  x2="80.0444"
                  y2="66.1519"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4DCC46" />
                  <stop offset="0.3216" stop-color="#51C122" />
                  <stop offset="0.6002" stop-color="#54BA09" />
                  <stop offset="0.7701" stop-color="#55B700" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_13_3306"
                  x1="10.2504"
                  y1="23.7423"
                  x2="79.6741"
                  y2="23.1452"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" />
                  <stop offset="0.0567024" stop-color="#DBEDE3" />
                  <stop offset="0.1333" stop-color="#B0D7C2" />
                  <stop offset="0.2024" stop-color="#91C7AB" />
                  <stop offset="0.2609" stop-color="#7EBD9C" />
                  <stop offset="0.3017" stop-color="#77BA97" />
                  <stop offset="0.3986" stop-color="#7DBD9C" />
                  <stop offset="0.5284" stop-color="#8EC6A9" />
                  <stop offset="0.6767" stop-color="#AAD4BE" />
                  <stop offset="0.8383" stop-color="#D1E8DC" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          <div className="max-w-full overflow-hidden inline-flex items-center font-title bg-[#3A8A00] border-4 border-[#00A2D4] px-5 py-3 rounded-full shadow-lg">
            <h2 className="mr-2">Bet:</h2>
            <span>$</span>
            <input
              className="outline-none bg-[#3A8A00] max-w-xs"
              placeholder="10000"
              type="number"
              value={inputValue}
              onChange={handleBetChange}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Game;
