import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import abi from "../utils/abi";
import erc20Abi from "../utils/erc20";
import { ethers } from "ethers";


const useApp = () => {
  const { account, chainId } = useWeb3React();

  const signMessage = async (message) => {
    try {
      if (!account) return '';
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const messageHash = ethers.utils.hashMessage(message);
      const messageHashBytes = ethers.utils.arrayify(messageHash);
      const signature = await signer.signMessage(messageHashBytes);
      return signature;
    } catch (error) {
      return '';
    }
  };

  const deposit = useCallback(async (amount) => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );
    await approve();
    const tx = await contract.deposit(ethers.utils.parseUnits(amount, 9));
    await tx.wait();

  }, [account, chainId])

  const withdraw = useCallback(async (transactionId, amount, res) => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );
    await approve();
    const tx = await contract.withdraw(transactionId, amount, res.v, res.r, res.s);
    await tx.wait()

  }, [account, chainId])

  const approve = useCallback(async () => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const busdContract = new ethers.Contract(process.env.REACT_APP_TOKEN_CONTRACT, erc20Abi, provider.getSigner())
    const allow = await busdContract.allowance(account, process.env.REACT_APP_CONTRACT)
    const allowNumber = +ethers.utils.formatUnits(allow, 18);
    if (allowNumber <= 1000) {
      const txApprove = await busdContract.approve(process.env.REACT_APP_CONTRACT, '115792089237316195423570985008687907853269984665640564039457584007913129639935');
      await txApprove.wait()
    }
  }, [account, chainId])


  return { signMessage, deposit, withdraw }
};

export default useApp;
