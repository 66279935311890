import React from "react";
import logo from "../../assets/images/logo.png";
import tw from "../../assets/images/tw.svg";
import tg from "../../assets/images/tg.svg";

function Footer(props) {
  return (
    <footer className="py-5">
      <div className="container mx-auto px-16 flex flex-col-reverse items-center justify-between lg:flex-row">
        <div className="max-w-[200px]">
          <img src={logo} alt="" />
        </div>
        <ul className="flex gap-5">
          <li>
            <a
              href="https://twitter.com/TurtleRacesETH"
              target="_blank"
              rel="noopener noreferrer">
              <img src={tw} />
            </a>
          </li>
          <li>
            <a
              href="https://t.me/TurtleRaces"
              target="_blank"
              rel="noopener noreferrer">
              <img src={tg} />
            </a>
          </li>
        </ul>
      </div>
      <p className="text-center">0xa611ea82cf9cd4cd0a4117139c3f61fd1e43a135</p>
      <p className="text-center">owner@turtleraces.top</p>
      <p className="text-center">Copyright TURTLERACES</p>
    </footer>
  );
}

export default Footer;
