import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";

import Home from "./pages/Home";

import "./App.scss";
import Game from "./pages/Game";
import Header from "./components/Header";
import Footer from "./components/footer";

function App() {
  const { loading } = useSelector((state) => state.loading);
  const [accountBalance, setAccountBalance] = useState(0);

  const receiveDataFromHeader = (data) => {
    setAccountBalance(data);
    console.log("data", data);
  };

  return (
    <div className="App overflow-hidden">
      <div
        className={`loading-wrapper ${loading ? "show-loading" : ""}`}
        style={{ zIndex: 99999 }}>
        <LoadingOverlay active spinner text="Waiting..."></LoadingOverlay>
      </div>

      <Header sendAccountBalance={receiveDataFromHeader} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game" element={<Game sendAccountBalance={receiveDataFromHeader} accountBalance={accountBalance} />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
